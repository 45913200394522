import axios from 'axios'
import { getStorage } from './storage'
import { Notice, Spin } from 'view-design'

/**
 * 封装一个不跳转url进行下载的方法
 * @param {String} url api地址，后面不用加“？”，前面也不需要加 API_URL
 * @param {Object} query 查询对象，形如 {foo:bar}，会解析成 “foo=bar”
 * @param {String} realFileName 下载文件名称，不知道为什么无法从response中获取
 */
export function downloadFileRequest (url, query, realFileName) {
  const qs = require('qs')
  const authToken = getStorage('ooh-token')
  const queryString = qs.stringify(query, { encode: true })
  let percent = 0
  let icount

  Spin.show({
    render: (h) => {
      return h('div', [
        h('div', {
          style: {
            color: '#FA8B59',
            fontSize: '16px',
            marginBottom: '15px'
          }
        }, 'May the oohforce be with you, Powered by OOHFORCE.COM'),
        h('progress', {
          domProps: {
            id: 'd-progress',
            value: percent,
            max: 100
          },
          style: { width: '400px', position: 'relative' }
        }),
        h('div', {
          domProps: {
            id: 'd-progress-text'
          },
          style: { color: '#333' }
        }, '0%')
      ])
    }
  })

  const server = axios.create()

  server.interceptors.request.use(function (config) {
    // Do something before request is sent
    const pg = document.getElementById('d-progress')
    const pgText = document.getElementById('d-progress-text')
    icount = setInterval(function () {
      if (pg.value < 95) {
        pg.value += 1
        pgText.innerText = pg.value + '%'
      }
    }, 1000)

    return config
  })

  server.get(queryString.length ? (url + '?' + queryString) : url, {
    responseType: 'blob',
    processData: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'ooh-auth': authToken
    },
    onDownloadProgress (progressEvent) {
      const pg = document.getElementById('d-progress')
      const pgText = document.getElementById('d-progress-text')
      percent = progressEvent.total > 0 ? Math.floor((progressEvent.loaded / progressEvent.total) * 100) : 0
      if (pg && pgText) {
        if (pg.value < percent) {
          pg.value = percent
          pgText.innerText = percent + '%'
        }
      }
    }
  }).then(response => {
    clearInterval(icount)
    const fileReader = new FileReader()
    fileReader.onload = function () {
      try {
        const jsonData = JSON.parse(this.result) // 说明是普通对象数据，后台转换失败
        if (jsonData.errcode) {
          Notice.warning({ desc: jsonData.errmsg })
        }
      } catch (errinfo) { // 解析成对象失败，说明是正常的文件流
        if (response.data && response.data instanceof Blob && response.data.type !== 'application/json') {
          const blob = new Blob([response.data], {
            type: response.data.type
          })
          // 获取后台文件名
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = realFileName
          // // 兼容火狐浏览器
          document.body.appendChild(link)
          const evt = document.createEvent('MouseEvents')
          evt.initEvent('click', false, false)
          link.dispatchEvent(evt)
          document.body.removeChild(link)
        } else {
          Notice.warning({ desc: '无数据导出！' })
        }
      }
    }
    fileReader.readAsText(response.data)

    Spin.hide()
  }).catch(err => {
    Spin.hide()
    Notice.error({ desc: '发生一个异常：' + err })
  })
}

/**
 * 封装一个不跳转url进行下载的方法
 * @param {String} url api地址，后面不用加“？”，前面也不需要加 API_URL
 * @param {Object} query 查询对象，形如 {foo:bar}，会解析成 “foo=bar”
 * @param {String} realFileName 下载文件名称，不知道为什么无法从response中获取
 */
export function downloadFilePostRequest (url, params, realFileName) {
  const param = new FormData()
  param.append('svg', params.svg)

  let percent = 0
  let icount

  Spin.show({
    render: (h) => {
      return h('div', [
        h('div', {
          style: {
            color: '#FA8B59',
            fontSize: '16px',
            marginBottom: '15px'
          }
        }, 'May the oohforce be with you, Powered by OOHFORCE.COM'),
        h('progress', {
          domProps: {
            id: 'd-progress',
            value: percent,
            max: 100
          },
          style: { width: '400px', position: 'relative' }
        }),
        h('div', {
          domProps: {
            id: 'd-progress-text'
          },
          style: { color: '#333' }
        }, '0%')
      ])
    }
  })

  const server = axios.create()

  server.interceptors.request.use(function (config) {
    // Do something before request is sent
    const pg = document.getElementById('d-progress')
    const pgText = document.getElementById('d-progress-text')
    icount = setInterval(function () {
      if (pg.value < 95) {
        pg.value += 1
        pgText.innerText = pg.value + '%'
      }
    }, 1000)

    return config
  })

  server.post(url,
    param,
    {
      responseType: 'blob',
      processData: true,
      headers: {
        'Content-Type': 'application/json',
        'ooh-auth': getStorage('ooh-token')
      },
      withCredentials: true,
      onDownloadProgress (progressEvent) {
        const pg = document.getElementById('d-progress')
        const pgText = document.getElementById('d-progress-text')
        percent = progressEvent.total > 0 ? Math.floor((progressEvent.loaded / progressEvent.total) * 100) : 0
        if (pg && pgText) {
          if (pg.value < percent) {
            pg.value = percent
            pgText.innerText = percent + '%'
          }
        }
      }
    }).then(response => {
    clearInterval(icount)
    const fileReader = new FileReader()
    fileReader.onload = function () {
      try {
        const jsonData = JSON.parse(this.result) // 说明是普通对象数据，后台转换失败
        if (jsonData.errcode) {
          Notice.warning({ desc: jsonData.errmsg })
        }
      } catch (errinfo) { // 解析成对象失败，说明是正常的文件流
        // if (response.data && response.data instanceof Blob && response.data.type !== 'application/json') {
        const blob = new Blob([response.data], {
          type: response.data.type
        })
        // 获取后台文件名
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = realFileName
        // // 兼容火狐浏览器
        document.body.appendChild(link)
        const evt = document.createEvent('MouseEvents')
        evt.initEvent('click', false, false)
        link.dispatchEvent(evt)
        document.body.removeChild(link)
        // } else {
        //   Notice.warning({ desc: '无数据导出！' })
        // }
      }
    }
    fileReader.readAsText(response.data)

    Spin.hide()
  }).catch(err => {
    Spin.hide()
    Notice.error({ desc: '发生一个异常：' + err })
  })
}

export function downloadImgRequest (url, imgElementId) {
  const authToken = getStorage('ooh-token')

  var oReq = new XMLHttpRequest()
  oReq.open('GET', url, true)
  oReq.setRequestHeader('ooh-auth', authToken)
  oReq.responseType = 'blob'
  oReq.onload = function (oEvent) {
    var blob = oReq.response // Note: not oReq.responseText
    document.getElementById(imgElementId).src = window.URL.createObjectURL(blob)
  }
  oReq.send()
}

export function downloadImg (url, width, height) {
  const authToken = getStorage('ooh-token')
  let newUrl = url
  if (width && width > 0) {
    newUrl += `&width=${width}`
  }
  if (height && height > 0) {
    newUrl += `&height=${height}`
  }

  return new Promise((resolve, reject) => {
    axios.get(newUrl, {
      headers: {
        'ooh-auth': authToken
      },
      responseType: 'blob'
    }).then(res => {
      var url = URL.createObjectURL(res.data)
      resolve(url)
    }).catch(err => {
      reject(err)
    })
  })
}
