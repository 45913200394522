<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col span="3">
        <div class="p-l-10 p-r-10 workplatform-radio-button-active">交易</div>
        <div
          class="p-l-10 p-r-10 workplatform-radio-button"
          v-if="isAuth('settlement_maintenance_view')"
          @click="handleChangeTag(2)"
        >
          运维
        </div>
        <div
          class="p-l-10 p-r-10 workplatform-radio-button"
          v-if="isAuth('performance_view')"
          @click="handleChangeTag(3)"
        >
          收款
        </div>
      </i-col>
      <i-col span="3">
        <Select size="small" v-model="billStatus" placeholder="结算状态">
          <Option
            v-for="item in confirmStatus"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="3">
        <DatePicker
          v-model="startDate"
          type="date"
          size="small"
          placeholder="起始日期"
        ></DatePicker>
      </i-col>
      <i-col span="3">
        <DatePicker
          v-model="endDate"
          type="date"
          size="small"
          placeholder="结束日期"
        ></DatePicker>
      </i-col>
      <i-col span="3">
        <Select
          v-model="currentCompanyId"
          clearable
          size="small"
          placeholder="公司列表"
        >
          <Option
            v-for="(company, index) in companyArray"
            :key="index"
            :value="company.id"
            >{{ company.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="3">
        <i-input
          v-model="keyword"
          size="small"
          clearable
          placeholder="如：编码、备注"
        ></i-input>
      </i-col>
      <i-col span="6">
        <Button
          type="primary"
          size="small"
          icon="ios-search"
          :loading="loadSearch"
          @click="search"
        >
          <span v-if="!loadSearch">搜索</span>
          <span v-else>加载中...</span>
        </Button>
        <Button
          type="success"
          size="small"
          class="m-l-5"
          @click="createNewSettlement"
          v-if="isAuth('settlement_transaction_create')"
          >新建结算</Button
        >
        <!-- <Button type="success" size="small" class="m-l-5" @click="createNewPayment">收款记录</Button> -->
        <Button
          type="success"
          size="small"
          class="m-l-5"
          @click="showDownLoadProgrammeModal = true"
          >下载明细</Button
        >
      </i-col>
    </Row>

    <createTransactionSettlement
      ref="createTransactionSettlement"
    ></createTransactionSettlement>
    <transactionData ref="transactionData"></transactionData>
    <Modal v-model="showDownLoadProgrammeModal">
      <h3 class="p-b-10" slot="header">下载确认</h3>
      <Row>
        <i-col span="24"  class="p-b-10">
          <h4 class="p-b-5">下载类型</h4>
          <RadioGroup v-model="downLoadProgramme">
            <Radio label="1">
              <span>全部下载</span>
            </Radio>
            <Radio label="2">
              <span>勾选下载</span>
            </Radio>
          </RadioGroup>
        </i-col>
        <i-col span="24" class="p-t-10">
          <h4 class="p-b-5">是否包含资源明细</h4>
          <RadioGroup v-model="feeItemExportType">
            <Radio label="2">
              <span>是</span>
            </Radio>
            <Radio label="1">
              <span>否</span>
            </Radio>
          </RadioGroup>
        </i-col>
      </Row>

      <div slot="footer">
        <Button type="text" @click="showDownLoadProgrammeModal = false"
          >取消</Button
        >
        <Button type="primary" @click="choiceDownLoadProgramme">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import createTransactionSettlement from './createTransactionSettlement'
// import createTransactionPayment from './createTransactionPayment'
import transactionData from './transactionData'

import { downloadFileRequest } from '@/utils/download'
import { ParseDate, ParseDateForMonth } from '@/utils/dateFormat'
import { getCompanyChild } from '@/api/os/company'

export default {
  components: {
    createTransactionSettlement,
    transactionData
  },
  data () {
    return {
      loadSearch: false,
      confirmStatus: [
        { id: -1, name: '全部状态' },
        { id: 0, name: '草稿' },
        { id: 1, name: '结算完成' }
      ],
      billStatus: -1,
      startDate: '',
      endDate: '',
      companyArray: [],
      currentCompanyId: null,
      keyword: '',
      showDownLoadProgrammeModal: false,
      downLoadProgramme: '1',
      feeItemExportType: '1'
    }
  },
  created () {
    this.initCompanyArray()
  },
  methods: {
    // 获取结算方公司列表
    initCompanyArray () {
      // getcompanylistbytype({ companyId: this.companyId, type: 1 }).then(res => {
      //   this.companyArray = res
      //   this.currentCompanyId = null
      //   this.search()
      // })
      getCompanyChild({
        companyId: this.companyId,
        type:
          this.$store.getters.token.userInfo.publisherId === this.companyId
            ? 1
            : 2,
        childType: 2,
        companyType: 1
      }).then((res) => {
        let array = [
          {
            id: res.companyId,
            name: res.companyName
          }
        ]
        if (res.children.length) {
          array = array.concat(
            this.formatCompanyArray(res.children).filter(
              (x) => x.id !== res.companyId
            )
          )
        }
        this.companyArray = array
        this.search()
      })
    },
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach((item) => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(
            this.formatCompanyArray(item.children)
          )
        }
      })
      return resultArray
    },
    search () {
      const query = {
        agentCompanyId: this.currentCompanyId,
        billStatus: this.billStatus,
        startDate: this.startDate ? ParseDate(this.startDate) : null,
        endDate: this.endDate ? ParseDate(this.endDate) : null,
        keyword: this.keyword
      }
      this.$store.commit('set_settlement_transactionSettlementQuery', query)
      this.$store.commit('set_settlement_update', new Date())
    },
    handleChangeTag (tag) {
      let moduleType = ''

      switch (tag) {
        case 1:
          moduleType = 'transaction' // 交易
          break
        case 2:
          moduleType = 'maintenance' // 运维
          break
        case 3:
          moduleType = 'performance' // 收款
          break
        default:
          break
      }
      this.$store.commit('set_settlement_moduleType', moduleType)
    },
    createNewSettlement () {
      this.$refs.createTransactionSettlement.showModal(
        this.companyArray,
        this.currentCompanyId
      )
    },
    // // 新建收支付款记录
    // createNewPayment () {
    //   this.$refs.createTransactionPayment.showModal(this.companyArray, this.currentCompanyId)
    // },
    choiceDownLoadProgramme () {
      const that = this
      const params = {
        feeItemExportType: this.feeItemExportType
      }
      if (parseInt(that.downLoadProgramme) === 1) {
        params.agentCompanyId = that.transactionSettlementQuery.agentCompanyId
        params.startDate = that.transactionSettlementQuery.startDate
        params.endDate = that.transactionSettlementQuery.endDate
        params.keyword = that.transactionSettlementQuery.keyword
        params.billStatus =
          that.transactionSettlementQuery.billStatus === -1
            ? null
            : that.transactionSettlementQuery.billStatus
        params.billType = 1
        params.publisherId = that.$store.getters.token.userInfo.publisherId
      } else {
        if (!that.feeitemIds.length) {
          that.$Notice.info({ desc: '请选择要下载的条目！' })
          return
        } else {
          params.billIdArrJsonStr = JSON.stringify(that.feeitemIds)
          params.billType = 1
        }
      }

      that.downLoadExecl(params)
    },
    downLoadExecl (params) {
      let dateStr = ParseDate(new Date())
      // 全部下载
      if (parseInt(this.downLoadProgramme) === 1) {
        if (params.startDate && params.endDate) {
          dateStr =
            ParseDateForMonth(params.startDate) +
            '至' +
            ParseDateForMonth(params.endDate)
        }
      }

      const filename = '交易结算单(' + dateStr + ').xlsx'
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v2/bill/exportbills',
        params,
        filename
      )
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.token.userInfo.companyId
    },
    transactionSettlementQuery () {
      return this.$store.state.settlement.transactionSettlementQuery
    },
    feeitemIds () {
      return this.$store.state.settlement.feeitemIds || []
    }
  }
}
</script>
